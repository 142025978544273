<template>
  <div class="row">
    <!--    MODALS-->
    <modal :adaptive="true" height="auto" name="upload-new-waybill-image" width="750">
      <div class="card card-custom">
        <div class="card-body card-body-scroll">
          <photo-recorder
              :model.sync="document"></photo-recorder>
          <file-input
              ref="imageFileInput"
              :model.sync="document"
              :title="$t('editSample.image')"
              accept="image/*"
              name="content-image"
              @fileChange="onDocumentChange"
          >
            <template v-slot:inputDesign>
              <div class="w-100 d-flex justify-content-center" @click="$refs.imageFileInput.openFileInput()">
                <span v-html="getIconByKey('icons.meeting.select-from-file', {
                      class: 'w-75px h-75px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </template>
          </file-input>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3 btn-pill"
                  type="button"
                  @click="cancelDocumentUpload">
            {{ $t('editSample.close') }}
          </button>
          <button class="btn btn-sm btn-primary font-weight-bold cursor-pointer btn-pill"
                  type="button"
                  @click="closeUploadDocumentModal">{{ $t('editSample.save') }}
          </button>
        </div>
      </div>
    </modal>
    <modal :adaptive="true" height="auto" name="waybill-transaction-history" width="1000">
      <waybill-transition-history-modal
          :transaction-type="currentTransactionType"
          :waybill="currentWaybill"
      ></waybill-transition-history-modal>
    </modal>
    <div class="col-md-12">
      <div id="filter-card" class="row justify-content-start items-baseline w-100 mb-2" style="margin-bottom: -5px;">
        <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
        <h3 id="page-title" class="d-inline-block">{{ $t('waybill.product_entry') }}</h3>
      </div>

      <dashboard-box :title="$t('waybill.production_exit_return_entry')">
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div class="row">
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                <date-picker-input
                    :disabled="true"
                    :has-bottom-margin="false"
                    :is-inline="false"
                    :is-valid="true"
                    :title="$t('waybill.action_date')"
                    label-class="text-lg"
                >
                </date-picker-input>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                <date-picker-input
                    :has-bottom-margin="false"
                    :is-inline="false"
                    :is-valid="waybill_date != null ? true : null"
                    :model.sync="waybill_date"
                    :requird="true"
                    :title="$t('waybill.waybill_date')"
                    label-class="text-lg"
                >
                </date-picker-input>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                <custom-multi-select
                    :has-bottom-margin="false"
                    :is-inline="false"
                    :item-per-row="1"
                    :max="1"
                    :model.sync="waybill_type_id"
                    :options="waybillTypeOptions"
                    :required="true"
                    :title="$t('waybill.waybill_type')"
                    class="text-lg"
                    input-width="150px"
                    name="wayBillType"
                    not-list="true"
                >
                </custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                <text-input
                    :has-bottom-margin="false"
                    :helper-text="$t('waybill.please_enter_document_no')"
                    :is-inline="false"
                    :is-valid="document_no == null ? null : true"
                    :model.sync="document_no"
                    :placeholder="$t('waybill.please_enter_document_no')"
                    :required="true"
                    :title="$t('waybill.document_no')"
                    label-class="text-lg"
                ></text-input>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                <custom-multi-select
                    :has-bottom-margin="false"
                    :is-inline="false"
                    :max="1"
                    :model.sync="supplier_company_id"
                    :not-list="true"
                    :options="companyOptions"
                    :title="$t('waybill.supplier_company')"
                    input-width="150px"
                    name="waybill_supplier_company"
                />
              </div>

              <div class="d-flex justify-content-end items-center">
                <img v-show="document"
                     id="waybill_document" :src="document"
                     alt=""
                     class="w-50px h-50px object-cover border-15px navy-blue-border-1px mr-5">
                <span
                    class="px-3 py-1 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                    @click="openUploadDocumentModal">
                  <span
                      v-html="getIconByKey('icons.waybill.add_document', { class: 'w-20px h-20px object-cover d-inline-block opacity-75 mr-2'})"></span>
                  {{ $t('waybill.upload_document').toUpperCase() }}
                </span>
              </div>

              <div v-if="isValidToContinue"
                   class="col-xl-2 col-lg-3 col-md-3 col-sm-12 d-flex flex-column justify-content-end pb-3">
                <div class="w-150px">
                  <button-with-icon
                      :icon-name="null"
                      :text="$t('waybill.continue').toUpperCase()"
                      class="font-weight-boldest"
                      size="lg"
                      @onClick="validateWaybillInputs"
                  ></button-with-icon>
                </div>
              </div>
            </div>
            <h3 v-if="showOrderDetail" v-b-toggle.order_form_entry
                class="cursor-pointer mt-1 d-flex justify-content-center" variant="info">
              <span v-html="getIconByKey('icons.waybill.down_arrow', {
               class: 'w-25px h-25px object-cover d-inline-block opacity-75'
                })">
                </span>
            </h3>
            <b-collapse v-if="showOrderDetail" id="order_form_entry" class="pt-1" visible>
              <template v-for="(waybillDetail, key) in orderDetails">
                <h4 v-b-toggle="`order_form_entry_detail_${key + 1}`"
                    class="d-inline-block cursor-pointer mt-3 text-primary bottom-border-1px-navy font-weight-boldest"
                    variant="info">
                  <span class="d-inline-block pr-2">{{ key + 1 }}. Satır</span>
                  <i class="fas fa-caret-down text-navy-blue opacity-75"></i>
                  <span v-if="orderDetails.length>1 && orderDetails.length!=1" class="cursor-pointer ml-1" @click="deleteWaybil(key)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-25px h-25px d-inline-block mr-3 '
                      })"></span>
                </h4>
                <br>

                <b-collapse :id="`order_form_entry_detail_${key + 1}`" class="mb-5" visible>
                  <div class="row mt-5">
                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                      <custom-multi-select
                          :has-bottom-margin="false"
                          :is-inline="false"
                          :model.sync="waybillDetail.instruction_type_id"
                          :options="instructionTypeOptions"
                          :title="$t('waybill.waybill_kind')"
                          input-width="150px"
                          max="1"
                          name="wayBillKind"
                          not-list="true"
                          @save="instructionTypeChanged($event, key)"
                      />
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                      <custom-multi-select
                          :has-bottom-margin="false"
                          :is-inline="false"
                          :model.sync="waybillDetail.waybillable_ids"
                          :options="waybillDetail.orderOptions"
                          :title="$t('waybill.order_no')"
                          input-width="150px"
                          max="1"
                          name="waybillable_ids"
                          object-key="name"
                          @save="waybillableIdsChanged($event, key)"
                      />
                    </div>

                    <div v-if="waybillDetail.waybillable" class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                      <text-input
                          :disabled="true"
                          :is-inline="false"
                          :model.sync="waybillDetail.waybillable.product_name"
                          :placeholder="$t('waybill.please_enter_product_name')"
                          :title="$t('waybill.product_name')"
                      ></text-input>
                    </div>
                    <div v-if="waybillDetail.waybillable" class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                      <custom-multi-select
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :model.sync="waybillDetail.product_color_id"
                          :options="waybillDetail.waybillable.detail"
                          :placeholder="$t('waybill.please_enter_product_color')"
                          :title="$t('waybill.product_type_color')"
                          max="1"
                          name="wayBillOrderColor"
                          not-list="true"
                          object-key="color"
                          @save="onSelectProduct($event, key)"
                      >
                      </custom-multi-select>
                    </div>
                    <div
                        v-if="Number(waybill_type_id) <= 2 && + waybillDetail.product_color_id && waybillDetail.waybillable"
                        class="col-xl-2 col-lg-2 col-md-2 col-sm-12 row mx-0">
                      <number-input
                          :disabled="true"
                          :is-inline="false"
                          :is-valid="waybillDetail.order_amount ? true : null"
                          :model="waybillDetail.waybillable.detail[waybillDetail.product_color_id].total"
                          :placeholder="$t('waybill.please_enter_order_amount')"
                          :title="$t('waybill.order_amount')"
                          name="color_number"
                          step="1"
                      ></number-input>
                    </div>
                    <div v-if="+waybillDetail.product_color_id && waybillDetail.waybillable"
                        class="col-xl-3 col-lg-2 col-md-2 col-sm-12 row mx-0">
                      <custom-multi-select
                          v-if="waybillDetail.waybillable"
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :model.sync="waybillDetail.unit_id"
                          :options="unitOptions"
                          :placeholder="$t('waybill.please_enter_product_color')"
                          :title="$t('waybill.unit')"
                          max="1"
                          name="wayBillOrderColor"
                          not-list="true"
                      >
                      </custom-multi-select>
                    </div>
                    <div v-if="+waybillDetail.product_color_id && waybillDetail.waybillable"
                        class="col-xl-2 col-lg-2 col-md-2 col-sm-12 row mx-0">
                      <number-input
                          :disabled="true"
                          :is-inline="false"
                          :is-valid="waybillDetail.order_amount ? true : null"
                          :model="waybillDetail.waybillable.detail[waybillDetail.product_color_id].remaining"
                          :placeholder="$t('waybill.please_enter_remaining_amount')"
                          :title="$t('waybill.remaining_amount')"
                          name="remaining_amount"
                          step="1"
                      ></number-input>
                    </div>

                    <div v-if="id" class="col-12 d-flex justify-content-between content-between mt-2 mb-5">
                        <span
                            class="px-3 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                            @click="showPreviousEntryTransitionHistory(key)">
                          <span v-html="getIconByKey('icons.waybill.history', {
                                  class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                            })">
                          </span>
                          {{ $t('waybill.entry_transition_history').toUpperCase() }}
                        </span>
                      <span
                          class="px-3 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                          @click="showPreviousExitTransitionHistory(key)">
                          <span v-html="getIconByKey('icons.waybill.history', {
                                  class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                            })">
                          </span>
                          {{ $t('waybill.exit_transition_history').toUpperCase() }}
                        </span>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 row mx-0">
                      <number-input
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :is-valid="waybillDetail.order_amount ? true : null"
                          :min="0"
                          :model.sync="waybillDetail.tare_amount"
                          :placeholder="$t('waybill.please_enter_tare_amount')"
                          :step="1"
                          :title="$t('waybill.tare_amount')"
                          name="remaining_amount"
                          step="1"
                      ></number-input>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                      <number-input
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :is-valid="waybillDetail.fabric_roll ? true : null"
                          :min="0"
                          :model.sync="waybillDetail.fabric_roll"
                          :placeholder="$t('waybill.please_enter_number_of_roll')"
                          :step="1"
                          :title="$t('waybill.number_of_roll')"
                      ></number-input>
                    </div>

                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 mx-0">
                      <text-input
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :is-valid="waybillDetail.part_number ? true : null"
                          :model.sync="waybillDetail.part_number"
                          :placeholder="$t('waybill.please_enter_part_number')"
                          :title="$t('waybill.part_number')"
                      ></text-input>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 row mx-0">
                      <number-input
                          :disabled="waybillDetail.isSubmitted || instruction_type_id == 7"
                          :is-inline="false"
                          :is-valid="waybillDetail.gross_amount ? true : null"
                          :min="0"
                          :model.sync="waybillDetail.gross_amount"
                          :placeholder="$t('waybill.please_enter_gross_amount')"
                          :step="1"
                          :title="$t('waybill.gross_amount')"
                      ></number-input>
                    </div>

                    <div v-if="+waybillDetail.product_color_id"
                         class="col-xl-3 col-lg-3 col-md-3 col-sm-12 row mx-0 d-ruby">
                      <number-input
                          :disabled="waybillDetail.isSubmitted  || (waybillDetail.store_id === 8 && waybillDetail.hasOwnProperty('data') && waybillDetail.data)"
                          :is-inline="false"
                          :max="maxInputCheck(waybillDetail)"
                          :min="0"
                          :model.sync="waybillDetail.net_amount"
                          :placeholder="$t('waybill.please_enter_net_amount')"
                          :step="1"
                          :title="$t('waybill.net_amount')"
                      ></number-input>
                      <span @click="getWeight(waybillDetail, null)" v-html="getIconByKey('icons.waybill.weight',
                          {class: 'w-=25px h-25px object-cover item-end d-inline-block fond-red opacity-75 mr-2',})">
                      </span>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                      <custom-multi-select
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :model.sync="waybillDetail.store_id"
                          :options="storeOptions"
                          :placeholder="$t('waybill.please_enter_depo_type')"
                          :title="$t('waybill.depo_type')"
                          max="1"
                          name="wayBillOrderStoreOptions"
                          not-list="true"
                      >
                      </custom-multi-select>
                    </div>

                    <template v-if="waybillDetail.hasOwnProperty('data') && waybillDetail.data">
                      <div class="flex-grow-1 mx-0">
                        <div
                            class="table-responsive h-100 navy-blue-border-top-1px navy-blue-border-left-1px navy-blue-border-bottom-1px border-15px">
                          <table class="table">
                            <thead>
                            <tr>
                              <th v-for="(value, key) in waybillDetail.data"
                                  :key="'size_and_color_body_size_name' + key"
                                  class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                                <div class="w-100 d-flex justify-content-center items-center">
                                  <span class="d-inline-block px-5">{{ value.name }}</span>
                                </div>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td v-for="(value, key) in waybillDetail.data"
                                  :key="'size_and_color_body_size_name' + key"
                                  class="navy-blue-border-right-1px">
                                <div class="d-flex justify-content-center">
                                  <input v-model="value.amount" class="form-control form-control-sm" type="text"
                                         @input="waybillDetailUpdate(waybillDetail)">
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>



                    </template>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12  d-flex align-items-center">
                      <b-form-checkbox
                          v-model="waybillDetail.ignore_calculation"
                          unchecked-value="0"
                          value="1"
                          size="md"
                          switch>
                        {{ $t('waybill.ignore_calculation') }}
                      </b-form-checkbox>
                    </div>


                    <template v-if="'extraAmount' in waybillDetail">
                      <div v-if="waybillDetail.extraAmount.length > 0" class="col-12 row px-0 my-5">
                        <div v-for="(value, indexAmount) in waybillDetail.extraAmount" class="col-3">
                          <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                            <number-input
                                :disabled="waybillDetail.isSubmitted"
                                :is-inline="false"
                                :min="0"
                                :model.sync="value.tare_amount"
                                :placeholder="$t('waybill.please_enter_tare_amount')"
                                :step="1"
                                :title="$t('waybill.tare_amount')"
                            ></number-input>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                            <number-input
                                :disabled="waybillDetail.isSubmitted"
                                :is-inline="false"
                                :min="0"
                                :model.sync="value.gross_amount"
                                :placeholder="$t('waybill.please_enter_gross_amount')"
                                :step="1"
                                :title="$t('waybill.gross_amount')"
                            ></number-input>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 pr-0 d-ruby">
                            <number-input
                                :disabled="waybillDetail.isSubmitted"
                                :is-inline="false"
                                :min="0"
                                :model.sync="value.net_amount"
                                :placeholder="$t('waybill.please_enter_net_coming_amount')"
                                :step="1"
                                :title="$t('waybill.net_amount')"
                            ></number-input>

                            <span @click="getWeight(waybillDetail, value)" v-html="getIconByKey('icons.waybill.weight', {
                                  class: 'w-=25px h-25px object-cover item-end d-inline-block fond-red opacity-75 mr-2',
                            })">
                          </span>
                          </div>
                        </div>
                      </div>
                      <div v-if="waybillDetail.extraAmount.length > 0" class="col-12 row px-0 my-5">
                        <div class="col-6">
                          <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                            <number-input
                                :disabled="true"
                                :is-inline="false"
                                :min="0"
                                :model="totalAmountDetailProxy(key).tare_amount"
                                :step="1"
                                :title="$t('waybill.total_tare_amount')"
                            ></number-input>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                            <number-input
                                :disabled="true"
                                :is-inline="false"
                                :min="0"
                                :model="totalAmountDetailProxy(key).gross_amount"
                                :step="1"
                                :title="$t('waybill.total_gross_amount')"
                            ></number-input>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                            <number-input
                                :disabled="true"
                                :is-inline="false"
                                :min="0"
                                :model="totalAmountDetailProxy(key).net_amount"
                                :step="1"
                                :title="$t('waybill.total_net_amount')"
                            ></number-input>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 row justify-content-end items-end px-0 my-5">

                        <span v-if="waybillDetail.extraAmount.length > 0"
                              @click="deleteMoreAmount(key, key)" v-html="getIconByKey('icons.sample.delete_red', {
                                  class: 'w-=25px h-25px object-cover item-end d-inline-block fond-red opacity-75 mr-2',
                            })">
                          </span>
                      </div>

                    </template>




                    <div v-if="!waybillDetail.isSubmitted"
                         class="col-12 d-flex justify-content-between mb-1 items-center">
                        <span
                            class="px-3 py-1 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                            @click="addMoreAmount(key)">
                          <span v-html="getIconByKey('icons.waybill.add_document', {
                                  class: 'w-20px h-20px object-cover d-inline-block opacity-75 mr-2',
                            })">
                          </span>
                          {{ $t('waybill.add_amount').toUpperCase() }}
                        </span>
                    </div>
                    <div v-if="!waybillDetail.isSubmitted"
                         class="col-12 d-flex justify-content-start mb-10">
                        <span
                            class="px-3 py-1 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                            @click="waybillDetail.showDescription = true ">
                          <span v-html="getIconByKey('icons.waybill.add_document', {
                                  class: 'w-20px h-20px object-cover d-inline-block opacity-75 mr-2',
                            })">
                          </span>
                          {{ $t('waybill.add_description').toUpperCase() }}
                        </span>
                    </div>
                    <div v-if="waybillDetail.showDescription" class="col-6">
                      <textarea-input
                          :is-inline="false"
                          :model.sync="waybillDetail.description"
                          :title="$t('waybill.description')"
                          name="description"
                      ></textarea-input>
                      <span @click="deleteWaybillDescription(key)" v-html="getIconByKey('icons.sample.delete_red', {
                                  class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                            })">
                          </span>
                    </div>

                    <div v-if="isValidToSubmit" class="col-12 row my-2">
                      <div class="col-12 d-flex justify-content-center">
                        <button-with-icon
                            :icon-name="null"
                            :text="$t('waybill.divide_product').toUpperCase()"
                            class="mx-1 font-weight-boldest"
                            size="lg"
                            @onClick="divideProduct(key)"
                        ></button-with-icon>
                      </div>
                    </div>

                    <div
                        v-if="waybillDetail.hasOwnProperty('divided_products') && waybillDetail.divided_products.length"
                        class="col-12 row my-5">
                      <template v-for="(divided_product, dp_index) in waybillDetail.divided_products">
                        <template v-if="!divided_product.is_submitted">
                          <div class="col-xl-11 col-lg-10 col-md-10 col-sm-8 row mx-0">
                            
                            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                              <custom-multi-select
                                  :disabled="true"
                                  :has-bottom-margin="false"
                                  :is-inline="false"
                                  :model.sync="divided_product.instruction_type_id"
                                  :options="instructionTypeOptions"
                                  :title="$t('waybill.waybill_kind')"
                                  input-width="150px"
                                  max="1"
                                  name="divided_product_instruction_type_id"
                                  not-list="true"
                              />
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                              <custom-multi-select
                                  :has-bottom-margin="false"
                                  :is-inline="false"
                                  :model.sync="divided_product.waybillable_ids"
                                  :options="divided_product.orderOptions"
                                  :title="$t('waybill.order_no')"
                                  input-width="150px"
                                  max="1"
                                  name="divided_product_waybillable_ids"
                                  object-key="name"
                                  @save="dividedProductWaybillableIdsChanged($event, key, dp_index)"
                              />
                            </div>

                            <div v-if="divided_product.waybillable" class="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                              <text-input
                                  :disabled="true"
                                  :is-inline="false"
                                  :model.sync="divided_product.waybillable.product_name"
                                  :placeholder="$t('waybill.please_enter_product_name')"
                                  :title="$t('waybill.product_name')"
                              ></text-input>
                            </div>
                            <div v-if="divided_product.waybillable" class="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                              <custom-multi-select
                                  :disabled="divided_product.isSubmitted"
                                  :is-inline="false"
                                  :model.sync="divided_product.product_color_id"
                                  :options="divided_product.waybillable.detail"
                                  :placeholder="$t('waybill.please_enter_product_color')"
                                  :title="$t('waybill.product_type_color')"
                                  max="1"
                                  name="divided_product_waybill_order_color"
                                  not-list="true"
                                  object-key="color"
                                  @save="onSelectProductDividedProduct($event, key, dp_index)"
                              >
                              </custom-multi-select>
                            </div>
                            <div
                                v-if="divided_product.product_color_id && divided_product.waybillable"
                                class="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                              <number-input
                                  :disabled="true"
                                  :is-inline="false"
                                  :is-valid="divided_product.order_amount ? true : null"
                                  :model="divided_product.waybillable.detail[divided_product.product_color_id].remaining"
                                  :placeholder="$t('waybill.please_enter_order_amount')"
                                  :title="$t('waybill.order_amount')"
                                  name="divided_product_color_number"
                                  step="1"
                              ></number-input>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-12">
                              
                              <number-input
                                  :disabled="is_submitting"
                                  :is-inline="false"
                                  :max="divided_product.parent_amount"
                                  :min="0"
                                  :model.sync="divided_product.amount"
                                  :name="`waybill_bill_dividend%${key}-${dp_index}`"
                                  :step="1"
                                  :title="$t('waybill.amount_to_be_shred')"
                                  @change="onShredAmountChange($event, key, dp_index)"
                              ></number-input>
                            </div>
                          </div>
                          <div class="col-12 my-3 d-flex justify-content-center">
                            <button-with-icon
                                :disabled="is_submitting"
                                :text="$t('general.give_up').toUpperCase()"
                                class="mx-1"
                                icon-name="icons.waybill.give_up"
                                size="lg"
                                @onClick="cancelDividedProduct(key, dp_index)"
                            ></button-with-icon>
                            <button-with-icon
                                :disabled="Number(divided_product.amount) <= 0 ||
                                  Number(divided_product.amount) > Number(divided_product.parent_amount) ||
                                  Number(divided_product.amount) > Number(divided_product.waybillable.detail[divided_product.product_color_id].total) ||
                                  is_submitting"
                                :text="$t('general.save').toUpperCase()"
                                class="mx-1"
                                icon-name="icons.waybill.save"
                                size="lg"
                                @onClick="submitDividedProduct(key, dp_index)"
                            ></button-with-icon>
                          </div>
                        </template>
                        <template v-if="divided_product.is_submitted">
                          <div class="col-12">
                            <h4 v-b-toggle="`divided_product_${dp_index + divided_product.parent_id}`"
                                class="d-inline-block cursor-pointer text-primary bottom-border-1px-navy font-weight-boldest"
                                variant="info">
                              <span class="d-inline-block pr-2">{{ order }}</span>
                              <i class="fas fa-caret-down text-navy-blue opacity-75"></i>
                            </h4>
                            <br>
                          </div>
                          <b-collapse :id="`divided_product_${dp_index + divided_product.parent_id}`"
                                      class="mb-5 col-12"
                                      visible>
                            <div class="col-xl-11 col-lg-10 col-md-10 col-sm-8 row d-flex justify-content-center">
                              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <custom-multi-select
                                    :disabled="waybillDetail.isSubmitted"
                                    :model.sync="waybillDetail.product_name"
                                    :options="productNameOptions"
                                    :placeholder="$t('waybill.please_enter_product_name')"
                                    :title="$t('waybill.product_name')"
                                    max="1"
                                    name="wayBillKind"
                                    not-list="true"
                                >
                                </custom-multi-select>
                              </div>
                              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                <custom-multi-select
                                    :disabled="waybillDetail.isSubmitted"
                                    :model.sync="waybillDetail.product_color_id"
                                    :options="colorOptions"
                                    :placeholder="$t('waybill.please_enter_product_color')"
                                    :title="$t('waybill.product_type_color')"
                                    max="1"
                                    name="wayBillOrderColor"
                                    not-list="true"
                                >
                                </custom-multi-select>
                              </div>
                              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 row mx-0">
                                <number-input-with-unit
                                    :disabled="waybillDetail.isSubmitted"
                                    :is-inline="true"
                                    :is-valid="waybillDetail.order_amount ? true : null"
                                    :min="0"
                                    :model.sync="waybillDetail.order_amount"
                                    :placeholder="$t('waybill.please_enter_order_amount')"
                                    :step="1"
                                    :title="$t('waybill.order_amount')"
                                    :unit-model.sync="waybillDetail.order_amount_unit_id"
                                    :unitOptions="unitOptions"
                                ></number-input-with-unit>

                              </div>
                              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 row mx-0">
                                <number-input-with-unit
                                    :disabled="waybillDetail.isSubmitted"
                                    :is-inline="false"
                                    :is-valid="waybillDetail.order_amount ? true : null"
                                    :min="0"
                                    :model.sync="waybillDetail.remaining_amount"
                                    :placeholder="$t('waybill.please_enter_remaining_amount')"
                                    :step="1"
                                    :title="$t('waybill.remaining_amount')"
                                    :unit-model.sync="waybillDetail.remaining_amount_unit_id"
                                    :unitOptions="unitOptions"
                                ></number-input-with-unit>
                              </div>
                            </div>
                            <div class="col-12 row mt-5 mb-2">
                              <div class="col-12 d-flex justify-content-center">
                                <a :href="`${baseUrl}/#/waybill/${waybillDetail.id}/download-pdf`"
                                   class="px-3 navy-blue-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer text-navy-blue"
                                   target="_blank">
                            <span v-html="getIconByKey('icons.waybill.pdf', {
                                    class: 'w-35px h-35px object-cover d-inline-block opacity-75',
                              })">
                            </span>
                                  {{ $t('waybill.create_pdf').toUpperCase() }}
                                </a>
                                <span
                                    class="px-3 navy-blue-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer">
                            <span v-html="getIconByKey('icons.waybill.excel', {
                                    class: 'w-35px h-35px object-cover d-inline-block opacity-75'
                              })">
                            </span>
                            {{ $t('waybill.create_excel').toUpperCase() }}
                        </span><a :href="`${baseUrl}/#/waybill/tag/${waybillDetail.id}/download-pdf`"
                                  class="px-3 navy-blue-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer"
                                  target="_blank">
                            <span v-html="getIconByKey('icons.waybill.press_tag', {
                                    class: 'w-35px h-35px object-cover d-inline-block opacity-75'
                              })">
                            </span>
                                {{ $t('waybill.print_label').toUpperCase() }}</a>
                              </div>
                            </div>
                          </b-collapse>
                        </template>
                      </template>
                    </div>


                    <div v-if="isValidToSubmit" class="row px-5 py-3">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <span @click="addWaybillDescriptionGeneral()"
                              v-html="getIconByKey('icons.waybill.add_document', {
                          class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                          })">
                        </span>
                      </div>
                      <template v-for="(item, index) in description">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0">
                          <text-input
                              :has-bottom-margin="false"
                              :helper-text="$t('waybill.general_description')"
                              :is-inline="false"
                              :is-valid="description[index] == null ? null : true"
                              :maxlength="50"
                              :model.sync="description[index]"
                              :placeholder="$t('waybill.general_description')"
                              :required="false"
                              label-class="text-lg"
                              title=""
                          ></text-input>
                          <span @click="deleteWaybillDescriptionGeneral(index)"
                                v-html="getIconByKey('icons.sample.delete_red', {
                          class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                          })">
                          </span>
                        </div>
                      </template>
                    </div>
                    
                    <div v-if="orderDetails.length === key + 1 && isValidToSubmit" class="col-12 row my-2">
                      <div class="col-12 d-flex justify-content-center">
                        <button-with-icon
                            :icon-name="null"
                            :text="$t('waybill.add_new_product').toUpperCase()"
                            class="mx-1 font-weight-boldest"
                            size="lg"
                            @onClick="addNewProduct()"
                        ></button-with-icon>
                      </div>
                    </div>
                    <div v-if="orderDetails.length === key + 1 && isValidToSubmit" class="col-12 row px-0 mx-0">
                      <div
                          class="col-12 d-flex justify-content-center my-2">
                        <span
                            class="px-3 navy-blue-border-1px rounded-full d-flex justify-content-center items-center w-125px mx-2 cursor-pointer"
                            @click="cancelWaybill(key)">
                            <span v-html="getIconByKey('icons.waybill.give_up', {
                                    class: 'w-35px h-35px object-cover d-inline-block opacity-75',
                              })">
                            </span>
                            {{ $t('general.give_up').toUpperCase() }}
                        </span>
                        <span
                            :class="{'navy-blue-border-1px text-navy-blue': isValidToSubmit, 'navy-blue-border-disabled-1px text-disabled-navy-blue': !isValidToSubmit}"
                            class="px-3 rounded-full d-flex justify-content-center items-center w-125px mx-2 cursor-pointer"
                            @click="submitWaybill"
                        >
                            <span v-html="getIconByKey('icons.waybill.save', {
                                    class: 'w-35px h-35px object-cover d-inline-block ' + (isValidToSubmit && !is_submitting ? 'opacity-75' : 'opacity-45'),
                              })">
                            </span>
                            {{ $t('general.save').toUpperCase() }}
                        </span>
                      </div>
                      <div v-if="waybillDetail.isSubmitted" class="col-12 row my-2">
                        <div class="col-12 d-flex justify-content-center">
                          <a class="px-3 navy-blue-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer text-navy-blue"
                             @click="openInNewTab(waybillDetail.waybill_pdf_link)">
                            <span v-html="getIconByKey('icons.waybill.pdf', {
                                    class: 'w-35px h-35px object-cover d-inline-block opacity-75',
                              })">
                            </span>
                            {{ $t('waybill.create_pdf').toUpperCase() }}
                          </a>
                          <a class="px-3 navy-blue-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer text-navy-blue"
                             @click="openInNewTab(waybillDetail.waybill_excel_link)">
                            <span v-html="getIconByKey('icons.waybill.excel', {
                                    class: 'w-35px h-35px object-cover d-inline-block opacity-75'
                              })">
                            </span>
                            {{ $t('waybill.create_excel').toUpperCase() }}
                          </a>

                          <a
                              class="px-3 navy-blue-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer"
                              @click="openInNewTab(waybillDetail.waybill_tag_pdf_link)">
                            <span v-html="getIconByKey('icons.waybill.press_tag', {
                                    class: 'w-35px h-35px object-cover d-inline-block opacity-75'
                              })">
                            </span>
                            {{ $t('waybill.print_label').toUpperCase() }}
                          </a>
                        </div>
                      </div>

                    </div>

                  </div>
                </b-collapse>

              </template>
            </b-collapse>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from 'vuex'

import DashboardBox from "@/assets/components/DashboardBox";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import QrcodeVue from "qrcode.vue";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import FileInput from "@/assets/components/inputs/FileInput";
import PhotoRecorder from "@/assets/components/recorder/PhotoRecorder";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import $ from 'jquery'
import WaybillTransitionHistoryModal from "@/view/pages/waybill/detail/layouts/WaybillTransitionHistoryModal";
import {
  CREATE_ITEM,
  ERROR,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING,
  SET_LOADING
} from "@/core/services/store/REST.module";
import NumberInput from "@/assets/components/inputs/NumberInput";

import dummyOrderDetail from "./dummy-data/new-waybill-entry/order-detail";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import moment from 'moment';
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import _ from "lodash";
import supplierCompany from "@/core/services/services/supplierCompany";
import units from "@/core/services/services/units";
import waybillType from "@/core/services/services/waybillType";
import waybillStore from "@/core/services/services/waybillStore";
import instructionType from "@/core/services/services/instructionType";


export default {
  name: "NewWaybillEntry",
  mixins: [supplierCompany, units, waybillType, waybillStore, instructionType],

  components: {
    ButtonWithIcon,
    NumberInputWithUnit,
    TextareaInput,
    NumberInput,
    DashboardBox,
    TextInput,
    SelectInput,
    DatePickerInput,
    MultipleSelectInput,
    InfoTooltip,
    QrcodeVue,
    FileInput,
    PhotoRecorder,
    WaybillTransitionHistoryModal,
    CustomMultiSelect
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
    }),
    companyOptions() {
      if (this.supplierCompanyAllItems) {
        return this.convertArrayToObjectByKey(this.supplierCompanyAllItems, 'id', 'name');
      }

      return [];
    },
    instructionTypeOptionsRaw() {
      if (this.instructionTypeAllItems) {
        return this.instructionTypeAllItems;
      }

      return  [];
    },
    instructionTypeOptions() {
      if (this.instructionTypeAllItems) {
        return this.convertArrayToObjectByKey(this.instructionTypeAllItems, 'id', 'translations.0.name');
      }

      return  [];
    },
    storeOptions() {
      if (this.storeAllItems) {
        let temp = this.storeAllItems.filter(subItem => +subItem.parent_id !== 0);
        return this.convertArrayToObjectByKey(temp, 'id', 'translations.0.name');
      }

      return [];
    },
    waybillTypeOptions() {
      if (this.waybillTypeAllItems) {
        let tmpData = this.waybillTypeAllItems.filter(item => +item.type === 0);

        if (tmpData.length) {
          this.waybill_type_id = tmpData[0].id;
        }

        return this.convertArrayToObjectByKey(tmpData, 'id', 'translations.0.name');
      }

      return [];
    },
    unitOptions() {
      if (this.unitAllItems) {
        return this.convertArrayToObjectByKey(this.unitAllItems, 'id', 'translations.0.name');
      }

      return [];
    },
    totalSizeAndColor() {
      return function (item) {
        let items = _.values(item.data);
        let amount = 0;
        items.forEach(subItem => {
          amount += Number(subItem.amount);
        })

        return amount;
      }
    },
    selected_waybillable_ids() {
      if (this.waybillable_ids === undefined || this.waybillable_ids === null) return [];
      return this.waybillable_ids.map(id => {
        return this.orderOptions[id];
      });
    },
    totalAmountDetailProxy() {
      return function (key) {
        let self = this;
        let returnResult = {
          tare_amount: Number(self.orderDetails[key].tare_amount),
          tare_amount_unit_id: self.orderDetails[key].tare_amount_unit_id,
          gross_amount: Number(self.orderDetails[key].gross_amount),
          gross_amount_unit_id: Number(self.orderDetails[key].gross_amount_unit_id),
          net_amount: Number(self.orderDetails[key].net_amount),
          net_amount_unit_id: Number(self.orderDetails[key].net_amount_unit_id),
        }
        for (let i = 0; i < self.orderDetails[key].extraAmount.length; i++) {
          let temp = self.orderDetails[key].extraAmount[i];
          returnResult.tare_amount += Number(temp.tare_amount);
          returnResult.gross_amount += Number(temp.gross_amount);
          returnResult.net_amount += Number(temp.net_amount);
        }
        return returnResult;
      }
    },
    isValidToSubmit() {
      let orderDetails = this.orderDetails;
      let errors = [];

      let returnValue = true;
      orderDetails.forEach(item => {
        let currentWaybillDetail = item;
        if (!currentWaybillDetail || !currentWaybillDetail.waybillable) {
          returnValue = false;
          return false;
        }

        if (currentWaybillDetail.waybillable.product_name === null || currentWaybillDetail.waybillable.product_name.length === 0) {
          returnValue = false;
          return false;
        }
        if (Number(currentWaybillDetail.product_color_id) === 0) {
          returnValue = false;
          return false;
        }

        if (Number(currentWaybillDetail.gross_amount) === 0) {
          returnValue = false;
          return false;
        }

        if (Number(currentWaybillDetail.store_id) === 0) {
          returnValue = false;
          return false;
        }

        if (currentWaybillDetail.store_id !== 8) {
          if (Number(currentWaybillDetail.fabric_roll) === 0) {
            returnValue = false;
            return false;
          }

          if (!currentWaybillDetail.part_number) {
            returnValue = false;
            return false;
          }

          if (Number(currentWaybillDetail.tare_amount) === 0) {
            returnValue = false;
            return false;
          }
        }

        if (currentWaybillDetail.hasOwnProperty('extraAmount')) {
          for (let i = 0; i < currentWaybillDetail.extraAmount.length; i++) {
            let temp = currentWaybillDetail.extraAmount[i];
            if (Number(temp.tare_amount) === 0) {
              returnValue = false;
              return false;
            }
            if (Number(temp.gross_amount) === 0) {
              returnValue = false;
              return false;
            }
            if (Number(temp.net_amount) === 0) {
              returnValue = false;
              return false;
            }
          }
        }
      });

      return returnValue;
    },
    isValidToContinue() {
      if (this.waybill_date == null) return false;
      if (Number(this.waybill_type_id) === 0) return false;
      if (this.document_no == null) return false;
      if (this.supplier_company_id == null) return false;
      if (this.document == null) return false;

      return true;
    }
  },
  data() {
    return {
      waybill_date: moment().format(LARAVEL_DATE_FORMAT),
      waybill_type_id: null,
      instruction_type_id: null,
      document_no: null,
      supplier_company_id: null,
      waybillable_ids: [],
      deliverer: null,
      description: [],
      is_submitting: false,
      formattedOrderOptions: {},

      id: this.$route.params.id,

      productNameOptions: [],
      colorOptions: [],
      depoTypeOptions: [],
      orderDetails: [],
      showOrderDetail: false,
      document: null,
      currentWaybill: null,
      currentTransactionType: null,

      // urls
      companyUrl: 'api/supplier-companies',
      unitUrl: 'api/units',
      storeUrl: 'api/stores',
    };
  },
  methods: {
    deleteWaybil(index){
      if(this.orderDetails.length>1){
        this.orderDetails.splice(index, 1);
      }
    },
   
    maxInputCheck(waybillDetail){
      let max = 0;
      if(this.id){
        max = Number(waybillDetail.waybillable.detail[waybillDetail.product_color_id].amount) + Number(waybillDetail.waybillable.detail[waybillDetail.product_color_id].remaining);
      }else{
        max = Number(waybillDetail.waybillable.detail[waybillDetail.product_color_id].remaining);
      }
      if(this.isUserGranted('Staff', ['waybillStorageUser'], false, true)){
        max+=20;
      }
      return max;
      
    },
    waybillDetailUpdate(waybillDetail) {
      let amount = 0;
      let items = _.values(waybillDetail.data);
      items.forEach(subItem => {
        amount += Number(subItem.amount);
      })
      waybillDetail.net_amount = amount;
      waybillDetail.total_amount = amount;
    },
    onSelectProductDividedProduct(event, key, index) {
      let waybillDetail = this.orderDetails[key]
      let selectedItem = waybillDetail.divided_products[index].selectedOrderOption;
      let selectedItemDetail = selectedItem.detail[event.newValue[0]];

      waybillDetail.divided_products[index].waybillable_id = selectedItem.waybillable_id;
      waybillDetail.divided_products[index].waybillable_type = selectedItem.waybillable_type;
      waybillDetail.divided_products[index].instructable_id = selectedItemDetail.instructable_id
      waybillDetail.divided_products[index].instructable_type = selectedItemDetail.instructable_type;
      waybillDetail.divided_products[index].order_product_id = selectedItemDetail.order_product_id;
      waybillDetail.divided_products[index].order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
      waybillDetail.divided_products[index].unit_id = selectedItemDetail.amount_unit_id;
      this.orderDetails.splice(key, 1, waybillDetail);
    },
    onSelectProduct(event, key) {
      let waybillDetail = this.orderDetails[key]
      let selectedItem = this.orderDetails[key].selectedOrderOption;
      let selectedItemDetail = selectedItem.detail[event.newValue[0]];

      waybillDetail.waybillable_id = selectedItem.waybillable_id;
      waybillDetail.waybillable_type = selectedItem.waybillable_type;
      waybillDetail.instructable_id = selectedItemDetail.instructable_id
      waybillDetail.instructable_type = selectedItemDetail.instructable_type;
      waybillDetail.order_product_id = selectedItemDetail.order_product_id;
      waybillDetail.order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
      waybillDetail.unit_id = selectedItemDetail.amount_unit_id;
      waybillDetail.waybill_transaction_id = selectedItemDetail.waybill_transaction_id;
      waybillDetail.divided_products = [];

      if (selectedItemDetail.hasOwnProperty('data')) {
        waybillDetail.data = JSON.parse(selectedItemDetail.data);
      }

      this.orderDetails.splice(key, 1, waybillDetail);
    },
    waybillableIdsChanged(event, key) {
      if (event.newValue[0]) {
        this.orderDetails[key].waybillable = null;
        this.orderDetails[key].product_color_id = null;
        this.orderDetails[key].selectedOrderOption = this.orderDetails[key].orderOptions[event.newValue[0]];
        this.orderDetails[key].waybillable = this.orderDetails[key].orderOptions[event.newValue[0]];
        this.orderDetails[key].divided_products = [];
      }
    },
    dividedProductWaybillableIdsChanged(event, key, index) {
      if (event.newValue[0]) {
        let waybillDetail = this.orderDetails[key]
        waybillDetail.divided_products[index].waybillable = null;
        waybillDetail.divided_products[index].product_color_id = null;
        waybillDetail.divided_products[index].selectedOrderOption = this.orderDetails[key].divided_products[index].orderOptions[event.newValue[0]];
        waybillDetail.divided_products[index].waybillable = this.orderDetails[key].divided_products[index].orderOptions[event.newValue[0]];

        this.orderDetails.splice(key, 1, waybillDetail);
      }
    },
    divideInstructionTypeChanged(value, key, index) {
      let waybillDetail = this.orderDetails[key]
      waybillDetail.divided_products[index].waybillable = null;
      waybillDetail.divided_products[index].product_color_id = null;
      waybillDetail.divided_products[index].waybillable_ids = [];
      waybillDetail.divided_products[index].selectedInstructionTypeOption = this.instructionTypeOptionsRaw.find(item => +item.id === +value);
      this.orderDetails.splice(key, 1, waybillDetail);
      this.getWaybillOrderOptions(value, key, null, false, 2);
    },
    instructionTypeChanged(event, key) {
      if (event.newValue[0]) {
        this.orderDetails[key].waybillable = null;
        this.orderDetails[key].product_color_id = null;
        this.orderDetails[key].waybillable_ids = [];
        this.orderDetails[key].divided_products = [];
        this.orderDetails[key].selectedInstructionTypeOption = this.instructionTypeOptionsRaw.find(item => +item.id === +event.newValue[0]);
        this.getWaybillOrderOptions(event.newValue[0], key);
      }
    },
    cancelDividedProduct(key, dp_index) {
      if (this.is_submitting) return;
      this.is_submitting = true;

      let orderDetail = this.orderDetails[key];
      let sib_id = orderDetail.divided_products[dp_index].sibling_id;
      if (sib_id) {
        let sib_index = Object.keys(this.convertArrayToObjectByKey(this.orderDetails[key].divided_products, 'sibling_id')).indexOf(sib_id);
        this.orderDetails[key].divided_products[sib_index] = this.orderDetails[key].divided_products[dp_index].parent_amount;
      } else {
        this.orderDetails[key].net_amount = this.orderDetails[key].divided_products[dp_index].parent_amount;
      }
      this.orderDetails[key].divided_products.splice(dp_index, 1);
      this.sweetAlertSuccess(this.$t('general.successfully_deleted'));
      this.is_submitting = false;
    },
    submitDividedProduct(key, dp_index) {
      if (this.is_submitting) return;
      this.is_submitting = true;
      this.sweetAlertLoading();

      let self = this,
          tempCurrent = this.orderDetails[key].divided_products[dp_index],
          waybillTransactionData = this.orderDetails[key];

      let formData = {
        waybill_transaction_id: waybillTransactionData.id,
        amount: tempCurrent.amount,
        instructable_id: tempCurrent.instructable_id,
        instructable_type: tempCurrent.instructable_type,
        instruction_type_id: tempCurrent.instruction_type_id,
        order_product_id: tempCurrent.order_product_id,
        order_size_and_color_id: tempCurrent.order_size_and_color_id,
        product_color_id: tempCurrent.product_color_id,
        waybillable_id: tempCurrent.waybillable_id,
        waybillable_type: tempCurrent.waybillable_type,
      }

      this.$store.dispatch(CREATE_ITEM, {
        url: `api/waybills/${waybillTransactionData.waybill_id}/shred`,
        showLoading: false,
        contents: formData,
      }).then(response => {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_created')).then(() => {
            self.$router.push({name: 'waybill.index'});
          });
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      })
    },
    onShredAmountChange(payload, key, index) {
      let value = payload.target.value;
      let waybillDetail = this.orderDetails[key]
      waybillDetail.divided_products[index].amount = Number(value);
      this.orderDetails.splice(key, 1, waybillDetail);
    },
    cancelDocumentUpload() {
      this.document = null;
      this.closeUploadDocumentModal();
    },
    onDocumentChange() {
      const img = document.getElementById(`waybill_document`);
      if (img) {
        img.src = URL.createObjectURL(this.document)
      }
    },
    openUploadDocumentModal() {
      this.$modal.show('upload-new-waybill-image');
    },
    closeUploadDocumentModal() {
      this.$modal.hide('upload-new-waybill-image');
    },
    divideProduct(key, sibling_id = null) {
      let waybillDetail = this.orderDetails[key]
      console.log(waybillDetail);
      waybillDetail.divided_products =  [{
        amount: 0,
        instruction_type_id: waybillDetail.instruction_type_id,
        is_submitted: false,
        parent_amount: waybillDetail.remaining,
        orderOptions: {},
      }];

      this.orderDetails.splice(key, 1, waybillDetail);

      this.divideInstructionTypeChanged(waybillDetail.instruction_type_id, key, 0);
    },
    addNewProduct() {
      let object = _.clone(dummyOrderDetail);
      this.orderDetails.push(object);
    },
    validateWaybillInputs() {
      let error = null;

      if (this.waybill_date == null) {
        error = 'waybill.please_pick_waybill_date';
      }
      if (Number(this.waybill_type_id) === 0) {
        error = 'waybill.please_select_waybill_type';
      }

      if (this.document_no === null) {
        error = 'waybill.please_enter_document_no';
      }
      if (this.supplier_company_id === null) {
        error = 'waybill.please_select_supplier_company';
      }

      if (error > 0) {
        this.sweetAlertError(error)
      } else {
        this.showOrderDetail = true;
      }
    },
    submitWaybill() {
      if (!this.isUserGranted('Waybill', ['create', 'update'])) {
        return false;
      }
      if (this.is_submitting) return;
      this.is_submitting = true;
      if (!this.isValidToSubmit) {
        this.sweetAlertError('waybill.please_fill_all_the_required_fields_for_submission')
        this.is_submitting = false;
        return;
      }
      this.sweetAlertLoading();
      let currentOrderDetail = this.orderDetails;
      let form = new FormData();

      let transactions = [];

      let extraAmountError = null;
      this.orderDetails.forEach(transaction => {
        let tmpItem = {
          waybillable_id: transaction.waybillable_id,
          waybillable_type: transaction.waybillable_type,
          instructable_id: transaction.instructable_id,
          instructable_type: transaction.instructable_type,
          order_product_id: transaction.order_product_id,
          order_size_and_color_id: transaction.order_size_and_color_id,
          tare_amount: transaction.tare_amount,
          gross_amount: transaction.gross_amount,
          net_amount: transaction.net_amount,
          unit_id: transaction.unit_id,
          store_id: transaction.store_id,
          description: transaction.description,
          part_number: transaction.part_number,
          fabric_roll: transaction.fabric_roll,
          instruction_type_id: transaction.instruction_type_id,
          ignore_calculation: transaction.ignore_calculation,
          waybill_transaction_id: transaction.waybill_transaction_id
        };

        if (transaction.id) {
          tmpItem.id = transaction.id;
        }

        if (transaction.hasOwnProperty('extraAmount')) {
          transaction.extraAmount.forEach(extraAmount => {
            tmpItem.tare_amount = Number(transaction.tare_amount) + Number(extraAmount.tare_amount || 0);
            tmpItem.gross_amount = Number(transaction.gross_amount) + Number(extraAmount.gross_amount || 0);
            tmpItem.net_amount = Number(transaction.net_amount) + Number(extraAmount.net_amount || 0);
          })

          currentOrderDetail.extraAmount = [];
        }

        let remaning = transaction.waybillable.detail[transaction.product_color_id].remaining;

        if (this.id) {
          let amount = transaction.waybillable.detail[transaction.product_color_id].amount;
          if (Number(tmpItem.net_amount) > (Number(amount) + Number(remaning))) {
            extraAmountError = true;
          }
        } else {
          if (Number(tmpItem.net_amount) > Number(remaning)) {
            extraAmountError = true;
          }
        }

        if (transaction.hasOwnProperty('data') && transaction.data) {
          tmpItem.data = JSON.stringify(_.cloneDeepWith(transaction.data));
        }
        transactions.push(tmpItem);
      })

      if (extraAmountError) {
        this.sweetAlertClose();
        this.sweetAlertError(this.$t('waybill.enter_amount_not_gt_remaining_amount'));
        this.is_submitting = false;
        return false;
      }

      form.append('waybill_date', moment(this.waybill_date).format(LARAVEL_DATE_FORMAT));
      form.append('document_number', this.document_no)
      form.append('supplier_company_id', this.supplier_company_id);
      form.append('waybill_type_id', this.waybill_type_id);
      form.append('description', currentOrderDetail.description);
      form.append('transaction_type', 1);
      form.append('description', JSON.stringify(this.description));

      if (this.document) {
        form.append('file', this.document);
      }

      this.createFormDataForList(form, 'transactions', transactions)

      let self = this;
      this.$store.dispatch(CREATE_ITEM, {
        url: this.id ? 'api/waybills/' + this.id : 'api/waybills',
        acceptPromise: true,
        contents: form,
      }).then((result) => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_saved')).then(() => {
            self.$router.push({name: 'waybill.index'});
          });
          self.is_submitting = false;
        } else {
          self.is_submitting = false;
          let response = result.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.sweetAlertError(response.data.message);
          } else {
            self.sweetAlertError(response.data);
          }
        }
        self.sweetAlertClose();
      });
    },
    deleteWaybillDescription(key) {
      this.orderDetails[key].description = '';
      this.orderDetails[key].showDescription = false;
    },
    addWaybillDescriptionGeneral() {
      if (this.description.length < 6) {
        this.description.push("");
      }
    },
    deleteWaybillDescriptionGeneral(key) {
      this.description.splice(key, 1)
    },
    cancelWaybill(index) {
      // TODO: remove selected order
      if (index == null) {
        this.selected_waybillable_ids.splice(index, 1);
        return;
      }
      let self = this;
      this.sweetAlertConfirm('general.are_you_sure').then((result) => {
        if (result) {
          if (self.orderDetails[index].length === 1) {
            self.selected_waybillable_ids.splice(index, 1);
            self.orderDetails.splice(index, 1);
          } else {
            self.orderDetails[index].splice(key, 1);
          }
          if (self.selected_waybillable_ids.length === 0) {
            this.showOrderDetail = false;
          }
        }
      })
    },
    getWaybillOrderOptions(instruction_type_id = null, orderIndex, item = null, hideLoading = false, type = null) {
      let instructionTypeId = instruction_type_id ? instruction_type_id : this.instruction_type_id;
      if (this.supplier_company_id != null && instructionTypeId != null) {
        this.$store.dispatch(GET_ITEMS, {
          url: 'api/waybill/waybill-order-option',
          filters: {
            instruction_type_id: instructionTypeId,
            supplier_company_id: this.supplier_company_id,
            waybill_type_id: this.waybill_type_id,
            is_edit: this.id ? 1 : 0,
          },
          acceptPromise: true,
        }).then((result) => {
          let self = this;
          if (result.status) {
            if (type) {
              let waybillDetail = this.orderDetails[orderIndex]
              waybillDetail.divided_products[0].orderOptions = result.data;
              this.orderDetails.splice(orderIndex, 1, waybillDetail);
            } else {
              self.orderDetails[orderIndex].orderOptions = result.data;
            }
            if (item) {

              let prefix = "O";
              if (item.waybillable_type === "App\\Models\\Sample") {
                prefix = "S";
              }
              let orderOptions = result.data;
              let orderOption = prefix + "-" + item.waybillable_id;
              item.waybillable_ids = [prefix + "-" + item.waybillable_id];
              item.waybillable = null;
              item.product_color_id = null;
              item.selectedOrderOption = orderOptions[orderOption];
              item.waybillable = orderOptions[orderOption];
              let selectedItem = item.selectedOrderOption;
              let selectedItemDetail = selectedItem.detail[item.instructable_id];
              if(selectedItemDetail){
                item.product_color_id = item.instructable_id;
                item.waybillable_id = selectedItem.waybillable_id;
                item.waybillable_type = selectedItem.waybillable_type;
                item.instructable_id = selectedItemDetail.instructable_id;
                item.instructable_type = selectedItemDetail.instructable_type;
                item.order_product_id = selectedItemDetail.order_product_id;
                item.order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
                item.unit_id = selectedItemDetail.amount_unit_id;
                item.divided_products = [];
                item.orderOptions = orderOptions;
                if (item.hasOwnProperty('data') && item.data) {
                  item.data = JSON.parse(item.data);
                }
                self.orderDetails[orderIndex] = item;
              }


              if (hideLoading) {
                self.showOrderDetail = true;
                self.$store.commit(SET_LOADING, false);
              }
            }
          }
        });
      }
    },
    addMoreAmount(index) {
      this.orderDetails[index].extraAmount.push({
        tare_amount: 0,
        gross_amount: 0,
        net_amount: 0,
      })
    },
    deleteMoreAmount(index, indexAmount) {
      this.orderDetails[index].extraAmount.splice(indexAmount, 1);
    },
    showPreviousEntryTransitionHistory(index) {
      this.currentWaybill = this.orderDetails[index];
      this.currentTransactionType = 1;
      this.$modal.show('waybill-transaction-history');
    },
    showPreviousExitTransitionHistory(index) {
      this.currentWaybill = this.orderDetails[index];
      this.currentTransactionType = 0;
      this.$modal.show('waybill-transaction-history');
    },
    setUpForEdit() {
      let self = this;
      self.$store.commit(SET_LOADING, true);
      self.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: 'api/waybills/' + self.id,
        acceptPromise: true,
      }).then((response) => {
        if (response.status) {
          let waybill = response.data;
          self.deliverer = waybill.delivering;
          self.waybill_date = moment(waybill.waybill_date, LARAVEL_DATE_FORMAT);
          self.waybill_type_id = waybill.waybill_type_id;
          self.document_no = waybill.document_number;
          self.supplier_company_id = waybill.supplier_company_id;
          self.document = waybill.document;
          self.description = JSON.parse(waybill.description);

          self.orderDetails = waybill.transactions.map(transaction => {
            if (transaction.data === null || !transaction.data) {
              delete transaction.data;
            }
            return transaction;
          });

          self.orderDetails.forEach((item, index) => {
            let hideLoading = self.orderDetails.length - 1 === index;
            this.getWaybillOrderOptions(item.instruction_type_id, index, item, hideLoading);
          });
        }
      })
    },

    getWeight(waybillDetail, extraAmount = null) {
      let self = this;
      let conn = new WebSocket(localStorage.getItem(VUE_APP_WEIGHT_SOCKET_URL));
      console.log("con", conn)
      if (conn.readyState == 1) {
        conn.send("open COM1 9600 default" + "\n")
      } else if (conn.readyState == 3) {
        //window.open('file:///C:pb_start/serial-port-json-server.exe');
        self.sweetAlertError("Websoket çalışmıyor");
      }

      conn.onclose = function (evt) {
        console.log("<div><b>Connection closed.</b></div>");
        self.sweetAlertError("WebSoket bağlantısı kapalı");
        return false

      }
      let line = "";
      let stb = "D"; // Stabil/Dinamik

      conn.onmessage = function (evt) {
        if (evt.data != null) { //---- Data Geliyorsa
          if (evt.data.indexOf('{') == -1) {
            return false;
          }
          let data = JSON.parse(evt.data)
          if (data.D != null) { //----- Tartı değeri geliyorsa
            //   console.log("DATA", data.D, data.D.length)
            if (data.D.length > 1) {
              let line = data.D.trim();
              console.log("data", line)
            }

            if (extraAmount) {
              extraAmount.net_amount = line;
              extraAmount.gross_amount = line;
            } else {
              waybillDetail.net_amount = line;
              waybillDetail.gross_amount = line;
            }
          }
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("waybill.waybill"), route: "waybill.index"},
      {title: this.$t("waybill.new_waybill_entry")}
    ]);
    let self = this;

    if (this.isUserGranted('Waybill', ['create', 'update'])) {

      if (this.$route.params.id) {
        this.setUpForEdit();
      } else {
        let object = _.clone(dummyOrderDetail);
        this.orderDetails.push(object);
      }
    }
  },
  watch: {
    supplier_company_id(newValue, oldValue) {
      this.supplier_company_id = newValue;
      this.getWaybillOrderOptions();

      this.waybillable_ids = [];
    },
    orderDetails: {
      handler(newValue, oldValue) {
        if (newValue) {
          let self = this;
          newValue.forEach(item => {
            let unitValue = item.unit_id;
            this.remaining_amount_unit_id = unitValue;
            item.tare_amount_unit_id = unitValue;
            item.net_amount_unit_id = unitValue;
            item.remaining_amount_unit_id = unitValue;
            if (item.hasOwnProperty('data') && item.data) {
              let amount = 0;
              let items = _.values(item.data);

              items.forEach(subItem => {
                amount += Number(subItem.amount);
              })

              item.net_amount = amount;
              item.total_amount = amount;

              if (+self.instruction_type_id === 7) {
                item.gross_amount = item.net_amount;
              }
            }
          })
        }
      }, deep: true
    },
    waybill_type_id(newValue, oldValue) {
      $('#page-title').text(this.$t('waybill.product_entry'))
    },
  }
}
</script>

<style scoped>
.company-option-preview-image {
  width: 25px;
  border-radius: 100%;
  height: 25px;
  object-fit: cover;
  display: inline-block
}

*:focus {
  outline: none;
}

.d-ruby {
  display: ruby;
}
</style>
